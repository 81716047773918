import type { EVMProvider, Wallet } from '../../types';
import type { WalletConnectConnectorOptions } from './wallet-connect';
import { getWalletConnectConnectorLegacy } from './wallet-connect';
import { EVMConnector } from './base';
import { ConnectorNotFoundError } from '../../types';
import { isAndroid } from '../../utils/isMobile';

import MetaMaskLogo from '../logos/metamask_icon.png';
import { isMetaMask, isMobile } from '../../utils';

export class MetaMaskConnector extends EVMConnector {
    private metamaskProvider?: any;

    #getProvider;

    constructor({ chainId, getProvider }: { chainId?: number; getProvider: () => any }) {
        super(chainId);
        this.#getProvider = getProvider;
    }

    async connect(): Promise<EVMProvider> {
        const provider = await this.#getProvider();
        if (!provider) throw new ConnectorNotFoundError();
        if (provider.on) {
            provider.removeListener('accountsChanged', this.onAccountsChanged);
            provider.on('accountsChanged', this.onAccountsChanged);
            provider.removeListener('chainChanged', this.onChainChanged);
            provider.on('chainChanged', this.onChainChanged);
            provider.removeListener('disconnect', this.onDisconnect);
            provider.on('disconnect', this.onDisconnect);
        }
        // this.emit('message', { type: 'connecting' });
        try {
            const accounts = await provider.request({ method: 'eth_requestAccounts' }).catch((e: any) => {
                console.error('metamask request eth_requestAccounts error', e);
                if (e.code === 4001) {
                    throw new Error(e.message);
                }
                return [];
            });
            console.log('metamask eth_requestAccounts result:', accounts);
            // const isConnected = !!accounts[0];
            // if (isConnected && !isMobile()) {
            //     const permissions = await provider.request({ method: 'wallet_getPermissions' });
            //     console.log('metamask wallet_getPermissions result:', permissions);
            //     if (permissions.length === 0) {
            //         await provider.request({
            //             method: 'wallet_requestPermissions',
            //             params: [{ eth_accounts: {} }],
            //         });
            //     }
            // }
        } catch (error) {
            console.log('metamask connect error', error);
            throw new Error('User Rejected');
        }

        this.provider = provider;
        return provider;
    }

    async disconnect(): Promise<void> {
        this.provider?.removeListener('accountsChanged', this.onAccountsChanged);
        this.provider?.removeListener('chainChanged', this.onChainChanged);
        this.provider?.removeListener('disconnect', this.onDisconnect);
        this.provider = undefined;
    }

    async getProvider() {
        return this.#getProvider();
    }

    private onAccountsChanged = (accounts: string[]) => {
        console.log('metamask accountsChanged', accounts);
        if (accounts.length === 0) {
            this.isSwitchChain = false;
            this.onDisconnect();
        }
        this.emit('accountsChanged', accounts);
    };

    private onChainChanged = (chainId: string) => {
        this.isSwitchChain = true;
        this.emit('chainChanged', chainId);
    };

    private onDisconnect = () => {
        console.log('onDisconnect isSwitchChain', this.isSwitchChain);
        if (this.isSwitchChain || this.isSwitchChain === undefined) {
            this.isSwitchChain = false;
            return;
        }
        console.log('metamask on disconnect');
        this.provider = undefined;
        this.emit('disconnect');
    };
}

export const metaMask = (opts?: WalletConnectConnectorOptions): Wallet => {
    const providers = typeof window !== 'undefined' && window.ethereum?.providers;

    const isMetaMaskInjected =
        typeof window !== 'undefined' &&
        typeof window.ethereum !== 'undefined' &&
        (window.ethereum.providers?.some(isMetaMask) || window.ethereum.isMetaMask);
    const getProvider = () =>
        providers ? providers.find(isMetaMask) : typeof window !== 'undefined' ? window.ethereum : undefined;
    console.log('metaMask', providers, getProvider(), isMetaMaskInjected);

    const shouldUseWalletConnect = !isMetaMaskInjected;

    return {
        id: 'metamask',
        name: 'MetaMask',
        iconUrl: MetaMaskLogo,
        downloadUrls: {
            browserExtension: 'https://metamask.io/download',
            qrCode: 'https://metamask.io/download',
        },
        createConnector: (chain) => {
            const connector = shouldUseWalletConnect
                ? getWalletConnectConnectorLegacy({ ...opts, chainId: chain?.id, qrcode: opts?.qrcode || false })
                : new MetaMaskConnector({
                      chainId: chain?.id,
                      getProvider,
                  });

            const getUri = async () => {
                const { uri } = (await connector.getProvider()).connector;
                return isAndroid() ? uri : `https://metamask.app.link/wc?uri=${encodeURIComponent(uri)}`;
            };
            return {
                connector,
                mobile: {
                    getUri: shouldUseWalletConnect ? getUri : undefined,
                },
                qrCode: {
                    getUri: shouldUseWalletConnect ? getUri : undefined,
                },
            };
        },
    };
};

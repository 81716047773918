import type { Provider } from '@particle-network/connect';
import { useAccountInfo } from '../provider/ModalContext';

export function useParticleProvider(): Provider | undefined {
    const { particleProvider } = useAccountInfo();
    if (particleProvider) {
        return particleProvider;
    }
    // const connectKit = useConnectKit();
    // if (!connectKit) {
    //     return undefined;
    // }
    //TODO: adapt EVM and Solana
    // // @ts-ignore
    // if (connectKit?.particle?.particleProvider) {
    //     // @ts-ignore
    //     return connectKit?.particle.particleProvider;
    // } else {
    //     // @ts-ignore
    //     return new ParticleProvider(connectKit?.particle.auth);
    // }
    return undefined;
}

import type { EVMProvider, SolanaProvider, Wallet } from '../../types';
import { WalletConnectVersion } from '../../types';
import { isIOS } from '../../utils';
import WalletConnectLogo from '../logos/wallet_connect_icon.png';
import { EVMConnector } from './base';
import type { WalletConnectOptions } from './wallet-connect-v2';
import WalletConnectV2Connector from './wallet-connect-v2';
import EthereumProvider from '@walletconnect/legacy-provider';

export interface IClientMeta {
    description: string;
    url: string;
    icons: string[];
    name: string;
}

export interface IWalletConnectConnectorOptions {
    bridge?: string;
    qrcode?: boolean;
    qrcodeModalOptions?: { mobileLinks?: string[] };
    clientMeta?: IClientMeta;
    chainId?: number;
}
export type WalletConnectConnectorOptions = Omit<IWalletConnectConnectorOptions, 'chainId'>;

const switchChainAllowedRegex = /(imtoken|metamask|rainbow|trust wallet|uniswap wallet|ledger|particle)/i;

export default class WalletConnectConnector extends EVMConnector {
    private opts: IWalletConnectConnectorOptions;

    wcProvider?: any;

    constructor(opts: IWalletConnectConnectorOptions) {
        super(opts);
        this.opts = opts;
    }

    async connect(): Promise<EVMProvider | SolanaProvider> {
        const provider = await this.getProvider(true);
        provider.removeListener('accountsChanged', this.onAccountsChanged);
        provider.on('accountsChanged', this.onAccountsChanged);
        provider.removeListener('chainChanged', this.onChainChanged);
        provider.on('chainChanged', this.onChainChanged);
        provider.removeListener('disconnect', this.onDisconnect);
        provider.on('disconnect', this.onDisconnect);

        setTimeout(() => this.emit('message', { type: 'connecting' }), 0);

        await provider.enable();
        const walletName = provider.connector.peerMeta?.name ?? '';
        if (!switchChainAllowedRegex.test(walletName)) {
            // walletconnect can not await result
            this.switchChain = undefined;
        }
        const { ParticleDelegateProvider } = await import('@particle-network/provider');
        const particleProvider = new ParticleDelegateProvider(window.particle!.auth, provider);
        this.provider = particleProvider;
        return particleProvider;
    }

    async disconnect(): Promise<void> {
        if (this.wcProvider && this.wcProvider.disconnect) {
            this.wcProvider.removeListener('accountsChanged', this.onAccountsChanged);
            this.wcProvider.removeListener('chainChanged', this.onChainChanged);
            this.wcProvider.removeListener('disconnect', this.onDisconnect);
            await this.wcProvider.disconnect();
        }
        if (localStorage) {
            localStorage.removeItem('walletconnect');
        }
    }

    async getProvider(create?: boolean) {
        if (this.wcProvider && !create) {
            return this.wcProvider;
        }
        let qrcode = true;

        if (this.opts) {
            qrcode = typeof this.opts.qrcode !== 'undefined' ? this.opts.qrcode : qrcode;
        }

        const provider = new EthereumProvider({
            ...this.opts,
            qrcode,
        });
        this.wcProvider = provider;
        return provider;
    }

    private onAccountsChanged = (accounts: string[]) => {
        if (accounts.length === 0) {
            this.provider = undefined;
            this.wcProvider = undefined;
        }
        this.emit('accountsChanged', accounts);
    };

    private onChainChanged = (chainId: string) => {
        this.emit('chainChanged', chainId);
    };

    private onDisconnect = () => {
        if (this.wcProvider) {
            console.log('walletconnect disconnect');
            this.provider = undefined;
            this.wcProvider = undefined;
            this.emit('disconnect');
        }
    };
}

type SerializedOptions = string;
const sharedConnectors = new Map<SerializedOptions, WalletConnectConnector | WalletConnectV2Connector>();

function createConnector<V extends WalletConnectVersion>({
    options,
    version,
}: {
    options: V extends 'V1' ? IWalletConnectConnectorOptions : WalletConnectOptions;
    version: V;
}) {
    if (version === WalletConnectVersion.V1) {
        const connector = new WalletConnectConnector(options);
        sharedConnectors.set(JSON.stringify(options), connector);
        return connector;
    } else if (version === WalletConnectVersion.V2) {
        const connector = new WalletConnectV2Connector(options as WalletConnectOptions);
        sharedConnectors.set(JSON.stringify(options), connector);
        return connector;
    } else {
        throw new Error('Wallet Connect version not supported');
    }
}

export function getWalletConnectConnectorLegacy(options: IWalletConnectConnectorOptions): WalletConnectConnector {
    const serializedOptions = JSON.stringify(options);
    const sharedConnector = sharedConnectors.get(serializedOptions);

    return (sharedConnector ??
        createConnector({ options, version: WalletConnectVersion.V1 })) as WalletConnectConnector;
}

export function getWalletConnectConnector<V extends WalletConnectVersion>({
    options,
    version,
}: {
    options: V extends 'V1' ? IWalletConnectConnectorOptions : WalletConnectOptions;
    version: V;
}) {
    const serializedOptions = JSON.stringify(options);
    const sharedConnector = sharedConnectors.get(serializedOptions);
    return sharedConnector ?? createConnector({ options, version });
}

export function walletconnect<V extends WalletConnectVersion>({
    options,
    version,
}: {
    options: V extends 'V1' ? IWalletConnectConnectorOptions : WalletConnectOptions;
    version: V;
}): Wallet {
    return {
        id: 'walletconnect',
        name: 'WalletConnect',
        iconUrl: WalletConnectLogo,
        createConnector: (chain) => {
            //@ts-ignore
            const qrcode = isIOS() || options.qrcode || options.showQrModal;

            const config = isIOS() ? { ...options, qrcode: true, showQrModal: true } : options;
            const connector = getWalletConnectConnector({ options: config, version });
            const getUri =
                version === WalletConnectVersion.V1
                    ? async () => (await connector.getProvider()).connector.uri
                    : undefined;
            return {
                connector,
                mobile: {
                    getUri: qrcode ? undefined : getUri,
                },
                qrCode: {
                    getUri: qrcode ? undefined : getUri,
                },
            };
        },
    };
}

export const web3Modal = (opts?: Omit<WalletConnectConnectorOptions, 'qrcode'>): Wallet => ({
    id: 'web3Modal',
    name: 'WalletConnect',
    iconUrl: WalletConnectLogo,
    createConnector: (chain) => {
        const connector = getWalletConnectConnectorLegacy({ ...opts, chainId: chain?.id, qrcode: true });
        return {
            connector,
        };
    },
});

import type { Provider } from '@particle-network/connect';
import { isEVMProvider, getPublicAddress, getEVMChainById } from '@particle-network/connect';
import {
    useConnectAction,
    useConnectKit,
    useAccountInfo,
    useChain,
    useUserChains,
    useUnsupportedChain,
    useParticleAuthWalletSort,
    useIsCacheProvider,
} from '../provider/ModalContext';
import type { AuthType } from '@particle-network/auth';
import { useNetwork } from './useNetwork';
import { useCallback } from 'react';

interface ConnectOptions {
    id?: string;
    preferredAuthType?: AuthType;
    [key: string]: any;
}

export function useParticleConnect() {
    const { updateParticleProvider, updateAccount, updateConnectId } = useConnectAction();
    const { updateChain } = useChain();
    const { setAccountLoading } = useAccountInfo();
    const { changeNetWork } = useNetwork();
    const { userChains } = useUserChains();
    const { setUnsupportedChain } = useUnsupportedChain();
    const { particleAuthSort } = useParticleAuthWalletSort();
    const isCache = useIsCacheProvider();

    const connectKit = useConnectKit();

    const connectSuccess = useCallback(
        async (provider: Provider, id?: string) => {
            const account = await getPublicAddress(provider);
            updateParticleProvider?.(provider);
            updateAccount?.(account);
            updateConnectId?.(id ?? '');

            if (isEVMProvider(provider)) {
                const chainId: string = await provider.request({ method: 'eth_chainId' });
                const chain = getEVMChainById(Number(chainId));
                if (chain) {
                    updateChain?.({
                        id: Number(chainId),
                        name: chain.name,
                    });
                }
                setUnsupportedChain?.(!chain || !userChains.find((x) => x.id === chain.id));
            } else {
                updateChain?.({
                    id: 101,
                    name: 'Solana',
                });
                changeNetWork('solana');
                setUnsupportedChain?.(false);
            }
        },
        [updateChain, changeNetWork, getEVMChainById]
    );

    const connect = async ({ preferredAuthType, id, ...options }: ConnectOptions) => {
        if (connectKit) {
            if (id === 'particle') {
                const provider = await connectKit.connect(id, {
                    preferredAuthType,
                    ...options,
                });
                await connectSuccess(provider, preferredAuthType as AuthType);
                return provider;
            }
            const provider = await connectKit.connect(id, options);
            await connectSuccess(provider, id);
            return provider;
        } else {
            return Promise.reject('error : connect no found  connectKit');
        }
    };

    const disconnect = async (options?: any) => {
        try {
            if (connectKit) {
                await connectKit.disconnect(options);
                updateParticleProvider?.(undefined);
                updateAccount?.('');
                updateConnectId?.('');
                setUnsupportedChain?.(false);
                if (typeof window !== undefined) {
                    window?.localStorage?.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
                }
                changeNetWork?.('');
                updateChain?.(undefined);
            }
            return Promise.resolve('no connect');
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const cacheconnect = async (): Promise<Provider | undefined> => {
        if (connectKit && isCache) {
            const provider = await connectKit.connectToCachedProvider().catch((e) => {
                console.log('Error:connectToCachedProvider ', e);
                return undefined;
            });
            if (provider) {
                connectSuccess(provider, connectKit?.cachedProviderId?.() || '');
            }
            setAccountLoading?.(false);
            return provider;
        }
        setAccountLoading?.(false);
        return undefined;
    };

    // cacheconnect();

    return {
        connectKit,
        connect,
        disconnect,
        cacheconnect,
        _connectSuccess: connectSuccess,
    };
}

import React, { useState, useEffect, useCallback } from 'react';
import ConnectForm from '../ConnectForm';
import '../../styles/index.css';
import './index.css';
import { useParticleConnect } from '../../hooks/useParticleConnect';
import { useWalletMetas } from '../../hooks/useWalletMeta';
import type { GroupItem, AuthType } from './type';
import { isIOS, isMobile } from '@particle-network/connect';
import { useWalletSort, useParticleAuthWalletSort } from '../../provider/ModalContext';
import { useTranslation } from '../../provider/I18nContext';
import {
    apple_icon,
    discord_icon,
    email_icon,
    facebook_icon,
    github_icon,
    google_icon,
    linkedin_icon,
    microsoft_icon,
    phone_icon,
    twitch_icon,
    twitter_icon,
} from '../../assets/social-icons';
import what_img1 from '../../assets/images/what-img1.png';
import what_img2 from '../../assets/images/what-img2.png';

export interface ConnectModalProps {
    open: boolean;
    onClose: () => void;
}

export const AuthGroup: GroupItem[] = [
    {
        name: 'Email',
        key: 'email',
        icon: email_icon,
        methods: 'browser',
        data: {},
    },
    {
        name: 'Phone',
        key: 'phone',
        icon: phone_icon,
        methods: 'browser',
        data: {},
    },
    {
        name: 'Google',
        key: 'google',
        icon: google_icon,
        methods: 'browser',
        data: {},
    },
    {
        name: 'Apple',
        key: 'apple',
        icon: apple_icon,
        methods: 'browser',
        data: {},
    },
    {
        name: 'Facebook',
        key: 'facebook',
        icon: facebook_icon,
        methods: 'browser',
        data: {},
    },
    {
        name: 'Microsoft',
        key: 'microsoft',
        icon: microsoft_icon,
        methods: 'browser',
        data: {},
    },
    {
        name: 'Linkedin',
        key: 'linkedin',
        icon: linkedin_icon,
        methods: 'browser',
        data: {},
    },
    {
        name: 'Github',
        key: 'github',
        icon: github_icon,
        methods: 'browser',
        data: {},
    },
    {
        name: 'Discord',
        key: 'discord',
        icon: discord_icon,
        methods: 'browser',
    },
    {
        name: 'Twitch',
        key: 'twitch',
        icon: twitch_icon,
        methods: 'browser',
    },
    {
        name: 'Twitter',
        key: 'twitter',
        icon: twitter_icon,
        methods: 'browser',
    },
];

export function ConnectModal({ open, onClose }: ConnectModalProps) {
    const { connect, connectKit, _connectSuccess } = useParticleConnect();
    const isPhone = isMobile();
    const { walletSort } = useWalletSort();
    const { particleAuthSort } = useParticleAuthWalletSort();
    const wallets = useWalletMetas();
    const [isMoce, setIsMore] = useState(false);
    // eslint-disable-next-line
    const [authKey, setAuthKey] = useState(''); // Key of the selected item
    const [authItem, setAuthItem] = useState<GroupItem | undefined>(undefined);
    const [walletMetas, setWalletMetas] = useState<GroupItem[]>([]);

    const [loginStep, setLoginStep] = useState<string>('1');
    const [mobileUri, setMobileUri] = useState('');
    const [overflow, setOverflow] = useState<string[]>([]);
    const { t } = useTranslation();
    const initWallets = () => {
        //  每次打开model 获取最新的wallet
        if (open) {
            wallets.then((res) => {
                const walletsArr = res
                    .filter((item) => {
                        // 过滤移动端phantom入口
                        if (isMobile() && item.name === 'Phantom') {
                            if (typeof window === 'undefined' || !(window as any).phantom?.solana?.isPhantom) {
                                return false;
                            }
                        }
                        return true;
                    })
                    .map((item) => {
                        return {
                            name: item.name,
                            key: item.id,
                            icon: item.iconUrl,
                            methods: 'qrcode',
                            data: item,
                        };
                    });

                setWalletMetas(walletsArr);
                setIsMore(walletsArr.length === 0);
            });
        }
        // eslint-disable-next-line
    };

    useEffect(() => {
        setAuthKey('');
        if (open && walletMetas.length === 0) {
            initWallets();
        }
        if (open) {
            const htmlOverFlow = document.getElementsByTagName('html')[0].style.overflow;
            const bodyOverFlow = document.getElementsByTagName('body')[0].style.overflow;
            setOverflow([htmlOverFlow, bodyOverFlow]);
            document.getElementsByTagName('html')[0].style.overflow = 'hidden';
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        } else {
            document.getElementsByTagName('html')[0].style.overflow = overflow[0];
            document.getElementsByTagName('body')[0].style.overflow = overflow[1];
            setOverflow([]);
        }
    }, [open]);

    //  modal close callback
    const modalCloseHandle = (): void => {
        setAuthKey('');
        setLoginStep('1');
        onClose && onClose();
    };

    const connectWallet = async (preferredAuthType: AuthType) => {
        try {
            await connect({
                preferredAuthType,
                id: 'particle',
            });
            modalCloseHandle();
        } catch (error) {
            if (preferredAuthType === 'email' || preferredAuthType === 'phone') {
                setAuthKey('');
                setLoginStep('1');
            }
            console.log(error);
        }
    };

    // auth login click callback
    const groupItemClickHandle = useCallback(
        (key: string, source: string) => {
            console.log('click wallet item: ', key, source);
            if (key) {
                setAuthKey(key);
                if (source === 'particle') {
                    const item = AuthGroup.find((item) => item.name === key) || {
                        key: '',
                        name: '',
                        icon: undefined,
                        methods: '',
                    };
                    setAuthItem({ ...item } as GroupItem);
                    connectWallet(item.key as AuthType);
                } else {
                    const item = walletMetas.find((item) => item.name === key);
                    setAuthItem({ ...item } as GroupItem);
                    setMobileUri('');
                    if (item?.methods === 'qrcode') {
                        const id = item.key;
                        try {
                            const _connector = connectKit.getConnector(id);
                            if (_connector) {
                                let mobile = '';
                                connectKit.once('connect', (provider) => {
                                    _connectSuccess(provider, id);
                                    modalCloseHandle();
                                });
                                const messageCall = async (message) => {
                                    if (message?.type === 'connecting') {
                                        if (_connector?.mobile?.getUri && isMobile()) {
                                            mobile = await _connector.mobile.getUri();
                                            if (typeof window !== 'undefined') {
                                                window.localStorage.setItem(
                                                    'WALLETCONNECT_DEEPLINK_CHOICE',
                                                    JSON.stringify({
                                                        href: mobile.split('?')[0],
                                                        name: item.name,
                                                    })
                                                );
                                            }
                                        }
                                        if (mobile && isMobile() && !(isIOS() && id === 'walletconnect')) {
                                            console.log('mobile:', mobile);
                                            if (mobile.startsWith('http')) {
                                                const link = document.createElement('a');
                                                link.href = mobile;
                                                link.target = '_blank';
                                                link.rel = 'noreferrer noopener';
                                                link.click();
                                            } else {
                                                window.location.href = mobile;
                                            }
                                            return;
                                        }
                                        if (_connector && _connector?.qrCode && _connector?.qrCode?.getUri) {
                                            const uri = await _connector?.qrCode?.getUri();
                                            console.log('WalletConnect Uri', uri);
                                            if (!uri) {
                                                await connect({
                                                    id,
                                                });
                                                setMobileUri('');
                                                modalCloseHandle();
                                            } else {
                                                setMobileUri(uri);
                                            }
                                        } else {
                                            setMobileUri('');
                                        }
                                    }
                                };
                                // _connector.connector.off('message', messageCall);
                                _connector.connector.once('message', messageCall);
                                try {
                                    connectKit.connect(id).catch((error) => {
                                        setAuthItem(undefined);
                                        setAuthKey('');
                                        setMobileUri('');
                                        setLoginStep('1');
                                        console.log('connectKit connect error', error);
                                        if (error.message === 'ConnectorNotFoundError: Connector not found') {
                                            const url = isPhone
                                                ? item?.data?.downloadUrls?.qrCode
                                                : item?.data?.downloadUrls?.browserExtension;
                                            if (url) {
                                                window.open(url);
                                            }
                                        }
                                    });
                                } catch (error) {
                                    setMobileUri('');
                                    throw new Error('connectKit.connect : error: ' + JSON.stringify(error));
                                }
                            } else {
                                setMobileUri('');
                            }
                        } catch (error) {
                            setMobileUri('');
                        }
                    }
                }
            } else {
                setAuthItem(undefined);
                setAuthKey('');
            }
        },
        [authKey, walletMetas, connectKit]
    );

    const closeStep2 = () => {
        setLoginStep('1');
        setAuthKey('');
    };

    useEffect(() => {
        if (authItem && authItem?.key && authItem?.methods && authItem?.methods !== 'browser') {
            setLoginStep('2');
        }
    }, [authItem, mobileUri]);

    const GroupChilderRender = (props) => {
        const { childKey, groups } = props;
        const source = childKey === 'Particle Auth' ? 'particle' : 'wallet';

        const groupRender = groups.map((item, idx) =>
            childKey === 'Particle Auth' ? (
                isMoce || idx < 5 ? (
                    <div
                        key={source + idx.toString()}
                        onClick={() => groupItemClickHandle(item.name, source)}
                        className={`particle-connect-modal-group-item center-start ${
                            !isPhone ? 'particle-item-hover1' : ''
                        } ${authKey === item.name ? 'active' : ''}`}
                    >
                        <div className="particle-group-icon-box">
                            <img src={item.icon} alt="" />
                        </div>
                        {t(`ConnectModal.${item.key}`)}
                    </div>
                ) : null
            ) : (
                <div
                    key={source + idx.toString()}
                    onClick={() => groupItemClickHandle(item.name, source)}
                    className={`particle-connect-modal-group-item center-start ${
                        !isPhone ? 'particle-item-hover1' : ''
                    } ${authKey === item.name ? 'active' : ''}`}
                >
                    <div className="particle-group-icon-box">
                        <img src={item.icon} alt="" />
                    </div>
                    {item.name}
                </div>
            )
        );
        return (
            <div key={source}>
                <div className="particle-connect-modal-group-name">{childKey}</div>
                {groupRender}
                {childKey === 'Particle Auth' && particleAuthSort.length > 5 && walletMetas.length > 0 && (
                    <div className="particle-connect-more center-center" onClick={() => setIsMore(!isMoce)}>
                        {isMoce ? t('ConnectModal.less') : t('ConnectModal.more')}
                        <div className={`particle-icon_more ${isMoce ? 'show' : 'hide'}`}></div>
                    </div>
                )}
            </div>
        );
    };

    const getAuthWallet = () => {
        const arr: GroupItem[] = [];
        particleAuthSort.forEach((key) => {
            const obj = AuthGroup.find((item) => item.key === key);
            if (obj) {
                arr.push(obj);
            }
        });
        return arr;
    };
    const stopPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        return false;
    };
    return (
        <>
            {open ? (
                <>
                    {/* masker */}
                    <div
                        onTouchStart={stopPropagation}
                        onScroll={stopPropagation}
                        className="particle-connect-modal-container center-center show"
                        // onClick={modalCloseHandle}
                    ></div>
                    <div
                        className="particle-connect-modal-box show"
                        onTouchStart={stopPropagation}
                        onScroll={stopPropagation}
                    >
                        <div className="particle-connect-modal-close hover" onClick={modalCloseHandle}></div>
                        <div className="particle-connect-modal-flex start-start">
                            <div
                                className={`flex1 particle-connect-methods-list ${loginStep === '1' ? 'show' : 'hide'}`}
                            >
                                <div className="particle-connect-modal-title">{t('ConnectModal.title2')}</div>
                                <div className="particle-connect-modal-gourp-list">
                                    {(walletSort ?? []).length == 0 ? (
                                        <>
                                            <GroupChilderRender
                                                childKey={'Particle Auth'}
                                                groups={getAuthWallet()}
                                            ></GroupChilderRender>
                                            {walletMetas.length > 0 && (
                                                <GroupChilderRender
                                                    childKey={'Wallet'}
                                                    groups={walletMetas}
                                                ></GroupChilderRender>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {walletSort
                                                ?.filter((item) => {
                                                    return item !== 'Wallet' || walletMetas.length > 0;
                                                })
                                                ?.map((item, idx) => (
                                                    <GroupChilderRender
                                                        key={idx.toString()}
                                                        childKey={item}
                                                        groups={item === 'Wallet' ? walletMetas : getAuthWallet()}
                                                    ></GroupChilderRender>
                                                ))}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={`particle-connect-methods-form ${loginStep === '2' ? 'show' : 'hide'}`}>
                                <div className="particle-icon-back" onClick={() => closeStep2()}></div>
                                {authKey ? (
                                    <ConnectForm
                                        authItem={authItem}
                                        mobileUri={mobileUri}
                                        onClose={modalCloseHandle}
                                    ></ConnectForm>
                                ) : (
                                    <div className={'center-center flex-column particle-no-auth-key'}>
                                        <h3>{t('ConnectModal.title1')}?</h3>

                                        <div className="center-start particle-item-what">
                                            <img className="particle-icon-what" src={what_img1} alt="" />
                                            <div className="flex1">
                                                <h5>{t('ConnectModal.tip1')}</h5>
                                                <p>{t('ConnectModal.tip2')}</p>
                                            </div>
                                        </div>

                                        <div className="center-start particle-item-what">
                                            <img className="particle-icon-what" src={what_img2} alt="" />
                                            <div className="flex1">
                                                <h5>{t('ConnectModal.tip3')}</h5>
                                                <p>{t('ConnectModal.tip4')}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}

export default ConnectModal;

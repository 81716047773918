import type WalletConnectProvider from '@walletconnect/ethereum-provider';
import type { EthereumProviderOptions } from '@walletconnect/ethereum-provider/dist/types/EthereumProvider';
import type { EVMProvider } from '../../types';
import { EVMConnector } from './base';

export type WalletConnectOptions = {
    /**
     * WalletConnect Cloud Project ID.
     * @link https://cloud.walletconnect.com/sign-in.
     */
    projectId: EthereumProviderOptions['projectId'];
    /**
     * Metadata for your app.
     * @link https://docs.walletconnect.com/2.0/javascript/providers/ethereum#initialization
     */
    metadata?: EthereumProviderOptions['metadata'];
    /**
     * Whether or not to show the QR code modal.
     * @default true
     * @link https://docs.walletconnect.com/2.0/javascript/providers/ethereum#initialization
     */
    showQrModal?: EthereumProviderOptions['showQrModal'];
    /**
     * Options of QR code modal.
     * @link https://docs.walletconnect.com/2.0/web3modal/options
     */
    qrModalOptions?: EthereumProviderOptions['qrModalOptions'];

    chainId: number;
};

export default class WalletConnectV2Connector extends EVMConnector {
    #provider?: WalletConnectProvider;
    #initProviderPromise?: Promise<void>;

    readonly options: WalletConnectOptions;

    constructor(options: WalletConnectOptions) {
        super(options);
        this.options = options;
    }

    async connect(): Promise<EVMProvider> {
        const provider = await this.getProvider();
        this.#setupListeners();
        this.emit('message', { type: 'connecting' });
        await provider.connect();
        const { ParticleDelegateProvider } = await import('@particle-network/provider');
        const particleProvider = new ParticleDelegateProvider(window.particle!.auth, provider);
        this.provider = particleProvider;
        return particleProvider;
    }

    async disconnect() {
        const provider = await this.getProvider();
        try {
            await provider.disconnect();
        } catch (error) {
            if (!/No matching key/i.test((error as Error).message)) throw error;
        } finally {
            this.#removeListeners();
        }
        this.provider = undefined;
    }

    async getProvider(): Promise<WalletConnectProvider> {
        if (!this.#provider) await this.#createProvider();
        return this.#provider!;
    }

    async #createProvider() {
        if (!this.#initProviderPromise && typeof window !== 'undefined') {
            this.#initProviderPromise = this.#initProvider();
        }
        return this.#initProviderPromise;
    }

    async #initProvider() {
        const {
            default: EthereumProvider,
            OPTIONAL_EVENTS,
            OPTIONAL_METHODS,
        } = await import('@walletconnect/ethereum-provider');
        const { projectId, showQrModal = true, qrModalOptions } = this.options;
        this.#provider = await EthereumProvider.init({
            showQrModal,
            // qrModalOptions,
            projectId,
            optionalMethods: OPTIONAL_METHODS,
            optionalEvents: OPTIONAL_EVENTS,
            chains: [this.options.chainId],
            qrModalOptions: {
                ...qrModalOptions,
                themeVariables: {
                    ...qrModalOptions?.themeVariables,
                    '--w3m-z-index': '3000',
                },
            },
        });
    }

    #setupListeners() {
        if (!this.#provider) return;
        this.#removeListeners();
        this.#provider.on('accountsChanged', this.onAccountsChanged);
        this.#provider.on('chainChanged', this.onChainChanged);
        this.#provider.on('disconnect', this.onDisconnect);
        this.#provider.on('session_delete', this.onDisconnect);
        this.#provider.on('display_uri', this.onDisplayUri);
    }

    #removeListeners() {
        if (!this.#provider) return;
        this.#provider.removeListener('accountsChanged', this.onAccountsChanged);
        this.#provider.removeListener('chainChanged', this.onChainChanged);
        this.#provider.removeListener('disconnect', this.onDisconnect);
        this.#provider.removeListener('session_delete', this.onDisconnect);
        this.#provider.removeListener('display_uri', this.onDisplayUri);
    }

    private onAccountsChanged = (accounts: string[]) => {
        if (accounts.length === 0) {
            this.provider = undefined;
        }
        this.emit('accountsChanged', accounts);
    };

    private onChainChanged = (chainId: string) => {
        this.emit('chainChanged', chainId);
    };

    private onDisconnect = () => {
        if (this.provider) {
            this.provider = undefined;
            this.#provider = undefined;
            this.emit('disconnect');
        }
    };

    private onDisplayUri = (uri: string) => {
        this.emit('message', { type: 'display_uri', data: uri });
    };
}

import type { Wallet } from 'src/types';
import type { WalletConnectConnectorOptions } from './wallet-connect';
import { getWalletConnectConnectorLegacy } from './wallet-connect';

import OmniLogo from '../logos/omni_icon.png';
import { isAndroid } from '../../utils';

export const omni = (opts?: WalletConnectConnectorOptions): Wallet => ({
    id: 'omni',
    name: 'Omni',
    iconUrl: OmniLogo,
    downloadUrls: {
        qrCode: 'https://omniwallet.app.link',
    },
    createConnector: (chain) => {
        const connector = getWalletConnectConnectorLegacy({
            ...opts,
            chainId: chain?.id,
            qrcode: opts?.qrcode || false,
        });

        return {
            connector,
            mobile: {
                getUri: async () => {
                    const { uri } = (await connector.getProvider()).connector;
                    return isAndroid() ? uri : `https://links.steakwallet.fi/wc?uri=${encodeURIComponent(uri)}`;
                },
            },
            qrCode: {
                getUri: async () => (await connector.getProvider()).connector.uri,
            },
        };
    },
});

import type { WalletConnectConnectorOptions } from '../providers/connectors/wallet-connect';
import {
    injectedEVM,
    walletconnect,
    metaMask,
    rainbow,
    argent,
    omni,
    phantom,
    trust,
    coinbase,
    okx,
} from '../providers/connectors';
import type { Wallet } from '../types';
import { WalletConnectVersion } from '../types';
import { getInjectedProvider } from './provider-utils';
import { COINBASE, METAMASK, TRUST } from '../providers/injected';
import type { SolanaWalletConfig } from '../providers/connectors/wallet-adapter';

export function evmWallets(opts?: WalletConnectConnectorOptions): Wallet[] {
    const wallets = [
        metaMask({ ...opts, qrcode: false }),
        walletconnect({ options: { ...opts }, version: WalletConnectVersion.V1 }),
        rainbow({ ...opts, qrcode: false }),
        trust({ ...opts, qrcode: false }),
        coinbase(),
        argent({ ...opts, qrcode: false }),
        omni({ ...opts, qrcode: false }),
        okx({ ...opts, qrcode: false }),
    ];
    const providerInfo = getInjectedProvider();
    if (providerInfo) {
        if (
            providerInfo.check != METAMASK.check &&
            providerInfo.check != TRUST.check &&
            providerInfo.check != COINBASE.check
        ) {
            wallets.unshift(
                injectedEVM({
                    id: providerInfo.id,
                    name: providerInfo.name,
                    iconUrl: providerInfo.logo,
                })
            );
        }
    }
    return wallets;
}

export function solanaWallets(config?: SolanaWalletConfig): Wallet[] {
    const wallets = [phantom()];
    return wallets;
}

export function evmInjectedWallet(): Wallet | undefined {
    const providerInfo = getInjectedProvider();
    if (providerInfo) {
        return injectedEVM({
            id: providerInfo.id,
            name: providerInfo.name,
            iconUrl: providerInfo.logo,
        });
    }
    return undefined;
}

import type { Wallet } from 'src/types/types';
import { isAndroid } from '../../utils/isMobile';
import type { WalletConnectConnectorOptions } from './wallet-connect';
import { getWalletConnectConnectorLegacy } from './wallet-connect';

//@ts-ignore
import ArgentLogo from '../logos/argent_icon.png';

export const argent = (opts?: WalletConnectConnectorOptions): Wallet => ({
    id: 'argent',
    name: 'Argent',
    iconUrl: ArgentLogo,
    downloadUrls: {
        qrCode: 'https://argent.link/app',
    },
    createConnector: (chain) => {
        const connector = getWalletConnectConnectorLegacy({
            ...opts,
            chainId: chain?.id,
            qrcode: opts?.qrcode || false,
        });
        return {
            connector,
            mobile: {
                getUri: async () => {
                    const { uri } = (await connector.getProvider()).connector;
                    return isAndroid() ? uri : `https://argent.link/app/wc?uri=${encodeURIComponent(uri)}`;
                },
            },
            qrCode: {
                getUri: async () => (await connector.getProvider()).connector.uri,
            },
        };
    },
});

import type { Chain } from '@particle-network/common';
import type { EVMProvider, DownloadUrls, Wallet } from '../../types/types';
import { EVMConnector } from './base';
export class InjectedConnector extends EVMConnector {
    #getProvider;
    constructor({ chainId, getProvider }: { chainId?: number; getProvider?: () => any }) {
        super(chainId);
        this.#getProvider = getProvider;
    }
    async connect(): Promise<EVMProvider> {
        let provider;
        if (typeof window !== 'undefined' && window.ethereum) {
            provider = await this.getProvider();
            if (provider.on && provider.off) {
                provider.off('accountsChanged', this.onAccountsChanged);
                provider.on('accountsChanged', this.onAccountsChanged);
                provider.off('chainChanged', this.onChainChanged);
                provider.on('chainChanged', this.onChainChanged);
                provider.off('disconnect', this.onDisconnect);
                provider.on('disconnect', this.onDisconnect);
            }
            this.emit('message', { type: 'connecting' });
            await provider.request({ method: 'eth_requestAccounts' });

            if (!provider.isMetaMask && !provider.isParticle && !provider.isTrust) {
                this.switchChain = undefined;
            }
        } else {
            throw new Error('No Web3 Provider found');
        }
        this.provider = provider;
        return provider;
    }

    async disconnect(): Promise<void> {
        this.provider?.off?.('accountsChanged', this.onAccountsChanged);
        this.provider?.off?.('chainChanged', this.onChainChanged);
        this.provider?.off?.('disconnect', this.onDisconnect);
        this.provider = undefined;
    }

    async getProvider() {
        return this.#getProvider ? this.#getProvider() : window.ethereum;
    }

    private onAccountsChanged = (accounts: string[]) => {
        if (accounts.length === 0) {
            this.provider = undefined;
        }
        this.emit('accountsChanged', accounts);
    };

    private onChainChanged = (chainId: string) => {
        this.emit('chainChanged', chainId);
    };

    private onDisconnect = () => {
        this.provider = undefined;
        this.emit('disconnect');
    };
}

export interface InjectedOptions {
    id: string;
    name: string;
    iconUrl: string;
    downloadUrls?: DownloadUrls;
}

export const injectedEVM = (option?: InjectedOptions): Wallet => ({
    id: option?.id ?? 'injected',
    name: option?.name ?? 'Injected Wallet',
    iconUrl: option?.iconUrl ?? '',
    downloadUrls: option?.downloadUrls,
    createConnector: (chain?: Chain) => {
        return {
            connector: new InjectedConnector({ chainId: chain?.id }),
        };
    },
});

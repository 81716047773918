import type { Wallet } from '../../types';
import { isAndroid } from '../../utils';
import WalletIcon from '../logos/okxWallet.svg';
import { InjectedConnector } from './injected';
import type { WalletConnectConnectorOptions } from './wallet-connect';
import { getWalletConnectConnectorLegacy } from './wallet-connect';

export const okx = (opts?: WalletConnectConnectorOptions): Wallet => ({
    id: 'okx',
    name: 'OKX Wallet',
    iconUrl: WalletIcon,
    downloadUrls: {
        qrCode: 'https://okx.com/download',
        browserExtension: 'https://okx.com/download',
    },
    createConnector: (chain) => {
        const isOKXInjected =
            typeof window !== 'undefined' &&
            // @ts-expect-error
            typeof window.okxwallet !== 'undefined';

        const shouldUseWalletConnect = !isOKXInjected;

        const connector = shouldUseWalletConnect
            ? getWalletConnectConnectorLegacy({ ...opts, chainId: chain?.id, qrcode: opts?.qrcode || false })
            : new InjectedConnector({ chainId: chain?.id, getProvider: () => (window as any).okxwallet });

        const getUri = async () => {
            const { uri } = (await connector.getProvider()).connector;
            return isAndroid() ? uri : `okex://main/wc?uri=${encodeURIComponent(uri)}`;
        };

        return {
            connector,
            mobile: {
                getUri: shouldUseWalletConnect ? getUri : undefined,
            },
            qrCode: {
                getUri: shouldUseWalletConnect ? getUri : undefined,
            },
        };
    },
});

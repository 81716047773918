import { AddChainError, SwitchChainError, UserRejectedRequestError } from '../../types';
import { getChainInfo, getEVMChainById } from '../../types/chains';
import type { ProviderRpcError, EVMProvider, Provider, SolanaProvider } from '../../types';
import EventEmitter from 'eventemitter3';

export interface ConnecterEvents {
    connect(): void;
    disconnect(): void;
    chainChanged(chainId: string): void;
    accountsChanged(accounts: string[]): void;
    message(message: any): void;
}

export type ConnectKitConnector<C extends Connector = Connector> = {
    connector: C;
    mobile?: {
        getUri?: () => Promise<string>;
    };
    qrCode: () => Promise<string>;
};

export abstract class Connector extends EventEmitter<ConnecterEvents> {
    provider: Provider | undefined;
    constructor(options?: any) {
        super();
    }
    abstract connect(): Promise<Provider>;
    abstract disconnect(): Promise<void>;
}

export abstract class SolanaConnector extends Connector {
    declare provider: SolanaProvider | undefined;
}

export abstract class EVMConnector extends Connector {
    declare provider: EVMProvider | undefined;

    isSwitchChain?: boolean;

    switchChain? = async (chainId: number): Promise<void> => {
        if (!this.provider) {
            throw new Error('wallet not connect');
        }
        const providerChainId = await this.provider.request({ method: 'eth_chainId' });
        if (chainId === Number(providerChainId)) {
            return;
        }
        const chain = getEVMChainById(chainId);
        if (!chain) {
            throw new Error('chain not supported');
        }
        const chainInfo = getChainInfo(chain);
        if (!chainInfo) {
            throw new Error('chain not supported');
        }
        this.isSwitchChain = true;
        console.log('switchChain:', this.isSwitchChain);
        const id = `0x${chain.id.toString(16)}`;
        try {
            await this.provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: id }],
            });
            return;
        } catch (error: any) {
            console.log('EVM SwitchChain error', error);
            // Indicates chain is not added to provider
            if (
                error.code === 4902 ||
                ((error.code === -32000 || error.code === -32603) &&
                    error.message &&
                    error.message.startsWith('Unrecognized chain ID'))
            ) {
                try {
                    const params = {
                        chainId: id,
                        chainName: chainInfo.fullname,
                        nativeCurrency: chainInfo.nativeCurrency,
                        rpcUrls: [chainInfo.rpcUrl],
                        blockExplorerUrls: chainInfo.blockExplorerUrls,
                    };

                    if (chainInfo.blockExplorerUrls.length === 0) {
                        // @ts-ignore
                        delete params.blockExplorerUrls;
                    }
                    await this.provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [params],
                    });
                    return;
                } catch (addError) {
                    console.log('EVM AddEthereumChain error', addError);
                    this.isSwitchChain = false;
                    if (this.isUserRejectedRequestError(addError)) throw new UserRejectedRequestError(addError);
                    throw new AddChainError();
                }
            }
            if (this.isUserRejectedRequestError(error)) throw new UserRejectedRequestError(error);
            throw new SwitchChainError(error);
        }
    };

    private isUserRejectedRequestError(error: unknown) {
        return (
            (<ProviderRpcError>error).code === 4001 ||
            (<ProviderRpcError>error).message.startsWith('User rejected the request')
        );
    }
}

export interface ParticleConnector {
    connector: Connector;
    mobile?: {
        getUri?: () => Promise<string>;
    };
    qrCode?: {
        getUri?: () => Promise<string>;
    };
}
